<template>
  <v-select
    label="ルームタイプ"
    :items="items"
    :value="room"
    item-text="text"
    item-value="key"
    @change="$emit('update:room', $event)"
    :rules="rule"
    multiple
    chips
  ></v-select>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  props: {
    room: {
      type: Array,
    },
    floor: {
      type: String,
    },
    usage: {
      type: String,
    },
  },
  computed: {
    items: function () {
      if (this.floor === '5F') {
        switch (this.usage) {
          case 'web-mtg':
            return [{ key: 'small-meeting-room', text: '小会議室' }]
        }
        return this.items5F
      }
      if (this.floor === 'B1') {
        return this.itemsB1
      }
      return this.items2F
    },
  },
  data: function () {
    return {
      items2F: [
        { key: 'room1', text: 'Room1' },
        { key: 'room2', text: 'Room2' },
        { key: 'motidasi', text: '持ち出し' },
      ],
      items5F: [
        { key: 'seminar-A', text: 'セミナーA' },
        // { key: 'seminar-B', text: 'セミナーB' },
        { key: 'small-meeting-room', text: '小会議室' },
      ],
      itemsB1: [
        { key: 'catheter-room', text: 'Catheter Room' },
        { key: 'small-meeting-room', text: '小会議室' },
      ],
      rule: [
        function (val) {
          if (val.length === 0) {
            return 'ルームタイプを選択してください'
          } else {
            return true
          }
        },
      ],
    }
  },
})
</script>
