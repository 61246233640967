function getEndTime(date, startTime, duration) {
  const start =
    Date.parse(`${date}T${startTime}+09:00`) + 1000 * 60 * 60 * duration
  const endDate = new Date()
  endDate.setTime(start)
  return `${endDate.getHours()}:${('00' + endDate.getMinutes()).slice(-2)}`
}

export default {
  getEndTime
}
