const HolidayList = [
  '2020-08-10',
  '2020-09-21',
  '2020-09-22',
  '2020-11-03',
  '2020-11-23',
  '2021-01-01',
  '2021-01-11',
  '2021-02-11',
  '2021-02-23',
  '2021-03-20',
  '2021-04-29',
  '2021-05-03',
  '2021-05-04',
  '2021-05-05',
  '2021-05-06',
  '2021-05-07',
  '2021-07-22',
  '2021-07-23',
  '2021-08-09',
  '2021-09-20',
  '2021-09-23',
  '2021-11-03',
  '2021-11-23',
]

export default HolidayList
