<template>
  <div class="about">
    <v-app-bar dense flat color="#5bb4b4" dark v-if="reservedID === 'new'">
      <v-spacer />
      <v-btn text href="https://tact-tokyo.co.jp/index.html">top</v-btn>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on">東京</v-btn>
        </template>
        <template>
          <v-list class="caption">
            <v-list-item @click="jump('ROOMAB')">ROOM A/B 予約</v-list-item>
            <v-list-item @click="jump('WETLAB')">WET LAB 予約</v-list-item>
            <v-list-item @click="jump('CatheterRoom')"
              >CatheterRoom 予約</v-list-item
            >
          </v-list>
        </template>
      </v-menu>
      <v-btn text @click="jump('NLS')"> 名古屋 </v-btn>
      <v-btn text @click="jump('SLS')"> 札幌 </v-btn>
      <v-spacer />
    </v-app-bar>
    <v-breadcrumbs :items="crumbs" v-if="reservedID !== 'new'">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-form ref="tactRoom5F" v-model="formValidity">
      <v-container fluid>
        <EventCalendar :floor="floor" :reservedID="reservedID" />
        <v-row justify="space-around" align="center">
          <v-col cols="12" md="5">
            <v-row>
              <v-col cols="12" v-if="currentState === 1">
                <span class="red--text">
                  <b>未承認</b>
                </span>
              </v-col>
              <v-col cols="12" v-if="currentState === 2">
                <span class="green--text">承認済み</span>
              </v-col>

              <v-col cols="12" v-if="floor === 'NLS'">
                <v-select
                  :label="labels.division"
                  v-model="models.division"
                  :rules="divisionRules"
                  :items="NLSList"
                ></v-select>
              </v-col>

              <v-col cols="12" v-if="floor === 'SLS'">
                <v-select
                  label="ルームタイプ"
                  v-model="models.division"
                  :rules="roomtypeRules"
                  :items="NLSList"
                ></v-select>
              </v-col>
              <!-- 利用日詳細 -->
              <v-col cols="12" md="12">
                <v-text-field
                  :label="labels.desiredDate"
                  :value="desired"
                  :rules="desiredDateRules"
                  readonly
                ></v-text-field>
              </v-col>

              <!-- 利用内容 -->
              <v-col cols="12">
                <EventUsage
                  :usage.sync="usage"
                  :floor="displayFloorType(floor)"
                  v-if="viewUsage"
                />
              </v-col>

              <!-- ルームタイプ -->
              <v-col cols="12" v-if="floor !== 'NLS' && floor !== 'SLS'">
                <EventRoomName
                  :room.sync="customModels.roomTypes"
                  :floor="floor"
                  :usage="usage"
                />
              </v-col>
              <!-- 利用時間 -->
              <v-col cols="12" md="4">
                <v-select
                  :label="labels.duration"
                  v-model="models.duration"
                  :items="durationItems"
                  @change="clearStartTime"
                ></v-select>
              </v-col>

              <!-- 開始時間 -->
              <v-col cols="12" md="4">
                <v-select
                  :label="labels.startTime"
                  v-model="models.startTime"
                  :items="startTimeItems"
                  @focus="focusStartTime"
                  @change="selectStartTime"
                  :disabled="
                    !models.desiredDate || customModels.roomTypes.length === 0
                  "
                  :rules="startRule"
                ></v-select>
              </v-col>

              <!-- 終了時間 -->
              <v-col cols="12" md="4">
                <v-text-field
                  :label="labels.endTime"
                  v-model="models.endTime"
                  readonly
                  :disabled="
                    !models.desiredDate || customModels.roomTypes.length === 0
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="floor === '2F'">
                開始時間が選択できない場合、担当者までお問い合わせください。
              </v-col>

              <!-- 開始前の準備時間 -->
              <v-col cols="12" md="6" v-show="reservedID !== 'new'">
                <v-select
                  :label="labels.startBuffer"
                  v-model="models.startBuffer"
                  :items="bufferItems"
                ></v-select>
              </v-col>

              <!-- 終了後の後片付け時間 -->
              <v-col cols="12" md="6" v-show="reservedID !== 'new'">
                <v-select
                  :label="labels.endBuffer"
                  v-model="models.endBuffer"
                  :items="bufferItems"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>

          <!-- カレンダー表示 -->
          <v-col
            cols="12"
            md="7"
            order="first"
            order-md="last"
            justify="space-around"
          >
            <v-col cols="12">
              イベントの前後
              {{ floor !== '2F' ? '2時間' : '30分' }}
              は作業用バッファとして確保させていただいております
            </v-col>
            <v-row justify="space-around" align="start">
              <v-col cols="6">
                <v-row justify="center">
                  <v-date-picker
                    v-model="models.desiredDate"
                    locale="ja"
                    :allowed-dates="disallowed"
                    :max="floor === 'NLS' ? '' : '2021-04-30'"
                  ></v-date-picker>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                lg="6"
                class="font-weight-bold"
                order-lg="last"
                order="first"
              >
                <p>
                  ご利用可能時間
                  {{ floor !== '2F' ? '09:00 ~ 21:00' : '10:00 ~ 22:00' }}
                </p>
                <p v-if="floor !== '2F'">
                  会議でのご利用は原則、平日の 09:00 ~ 17:00 までとなります。
                </p>
                <p>ご予約の受付は利用日の5営業日前までとさせていただきます。</p>
                <p>※土日のご利用希望につきましてはお問い合わせください。</p>
              </v-col>
            </v-row>
            <v-col cols="12" v-if="!disabled">
              <v-checkbox
                v-model="hdialog"
                :label="`休日の選択を有効にする`"
                v-if="isShowHoliday()"
              ></v-checkbox>
            </v-col>
          </v-col>
        </v-row>

        <v-row>
          <!-- イベント名称 -->
          <v-col cols="12" v-if="displayFloorType(floor) === '5F'">
            <v-text-field
              :label="labels.event"
              v-model="models.event"
              :rules="eventRules"
            ></v-text-field>
          </v-col>

          <!-- 診療科 -->
          <v-col cols="12" v-if="displayFloorType(floor) === '5F'">
            <v-select
              :label="customLabels.medicalDepartment"
              v-model="customModels.medicalDepartment"
              :items="medicalDepartmentItems"
              chips
            ></v-select>
          </v-col>
          <v-col cols="12" v-if="isOtherMedicalDepartment">
            <v-text-field
              label="その他診療科"
              v-model="customModels.otherMedicalDepartment"
            ></v-text-field>
          </v-col>

          <!-- テーブルレイアウト -->
          <v-col cols="12" v-if="displayFloorType(floor) === '5F'">
            <v-select
              :label="customLabels.tableLayout"
              v-model="customModels.tableLayout"
              :items="tableLayoutItems"
              chips
              :rules="tableLayoutRules"
            ></v-select>
          </v-col>

          <!-- テーブルレイアウト その他 -->
          <v-col cols="12" v-if="isOtherLayout">
            <v-text-field
              :label="otherLayoutLabel"
              v-model="customModels.otherLayoutDetail"
            ></v-text-field>
          </v-col>

          <!-- 担当者名 -->
          <v-col cols="12">
            <v-text-field
              :label="labels.name"
              v-model="models.name"
              :rules="nameRules"
            ></v-text-field>
          </v-col>

          <!-- 事業所/所属部署 -->
          <v-col cols="12" md="6" v-if="floor !== '2F' && floor !== 'SLS'">
            <v-text-field
              :label="labels.division"
              v-model="models.division"
              :rules="divisionRules"
              :readonly="floor === 'NLS'"
            ></v-text-field>
          </v-col>

          <v-col cols="12" v-if="floor === 'SLS'">
            <v-text-field
              label="事業部"
              v-model="customModels.divisionDetail"
              :rules="divisionDetailRules"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              :label="labels.department"
              v-model="models.department"
              :rules="departmentRules"
            ></v-text-field>
          </v-col>

          <!-- 上長名 -->
          <v-col cols="12" v-if="displayFloorType(floor) !== '2F'">
            <v-text-field
              :label="labels.superior"
              v-model="models.superior"
              :rules="superiorRules"
            ></v-text-field>
          </v-col>

          <!-- メールアドレス -->
          <v-col cols="12" md="6">
            <v-text-field
              :label="labels.email"
              v-model="models.email"
              :rules="emailRules"
            ></v-text-field>
          </v-col>

          <!-- 電話番号 -->
          <v-col cols="12" md="6">
            <v-text-field
              :label="labels.phone"
              v-model="models.phone"
              :rules="phoneRules"
            ></v-text-field>
          </v-col>

          <!-- コストコード -->
          <v-col cols="12">
            <v-text-field
              :label="labels.costCode"
              v-model="models.costCode"
              :counter="10"
              :rules="costCodeRules"
              v-if="displayFloorType(floor) !== '2F'"
            ></v-text-field>
          </v-col>
          <v-col cols="12" v-if="floor === 'NLS' && models.division == 'BW'">
            <p>※ 東海・北陸1課：1000004009、東海・北陸2課：1000004754</p>
          </v-col>

          <!-- イベント名称 -->
          <v-col
            cols="12"
            v-if="
              displayFloorType(floor) === 'B1' ||
              displayFloorType(floor) === '2F'
            "
          >
            <v-text-field
              :label="labels.event"
              v-model="models.event"
              :rules="eventRules"
            ></v-text-field>
          </v-col>

          <v-col cols="12" v-if="displayFloorType(floor) == 'B1'">
            <KJ v-bind.sync="customModels.kjSettings" v-bind:floor="floor" />
          </v-col>
        </v-row>

        <!-- 利用人数 -->
        <v-row
          v-if="
            displayFloorType(floor) === '5F' ||
            displayFloorType(floor) === '2F' ||
            displayFloorType(floor) === 'SLS'
          "
        >
          <v-col cols="12">
            <v-input :messages="numMessages"
              >利用人数 (合計): {{ numTotal }}</v-input
            >
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              :label="customLabels.numbers.inHouse"
              v-model="customModels.numbers.inHouse"
              :rules="numberInHouseRules"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              :label="customLabels.numbers.outHouse"
              v-model="customModels.numbers.outHouse"
              :rules="numberOutHouseRules"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" v-if="displayFloorType(floor) === 'SLS'">
            <v-text-field
              :label="customLabels.numbers.other"
              v-model="customModels.numbers.other"
              :rules="numberOutHouseRules"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="displayFloorType(floor) == 'B1'">
          <v-col cols="12">
            <v-input :messages="numMessages"
              >利用人数 (合計): {{ totalB1 }}</v-input
            >
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              label="社内"
              v-model="customModels.B1.number.inHouse"
              :rules="numberInHouseRules"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              label="医師"
              v-model="customModels.B1.number.doctor"
              :rules="numberOutHouseRules"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              label="代理店"
              v-model="customModels.B1.number.agency"
              :rules="numberOutHouseRules"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              label="co-med"
              v-model="customModels.B1.number.coMed"
              :rules="numberOutHouseRules"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- その他 -->
        <v-row>
          <v-col cols="12">
            <v-textarea
              :label="displayOther"
              v-model="models.other"
              outlined
            ></v-textarea>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-row justify="center" v-if="reservedID === 'new'">
          <v-col class="text-center">
            <p class="red--text">キャンセルチャージにつきまして</p>
            <p>
              ご利用予定日の3日前より、ご予約の取消・日程変更をされた場合にキャンセルチャージが発生いたします。
            </p>
            <p class="red--text">
              <span class="mgr">3日前～前日５０％</span>当日１００％
            </p>
          </v-col>
        </v-row>

        <v-row justify="space-around">
          <!-- 確定 -->
          <v-col cols="12" md="2" v-if="reservedID !== 'new'">
            <v-btn color="primary" block @click.stop="cdialog = true"
              >承認</v-btn
            >
          </v-col>
          <!-- 一時保存 -->
          <v-col cols="6" md="2" v-if="reservedID !== 'new'">
            <v-btn color="success" block @click="validateSave">一時保存</v-btn>
          </v-col>
          <!-- 復元 -->
          <v-col cols="6" md="2" v-if="reservedID !== 'new'">
            <v-btn color="success" block @click="validateLoad">復元</v-btn>
          </v-col>
          <!-- 予約取り消し -->
          <v-col cols="12" md="2" v-if="reservedID !== 'new'">
            <v-btn color="accent" block @click="deleteEvent">
              予約取り消し
            </v-btn>
          </v-col>

          <v-col cols="12" md="2" v-if="reservedID !== 'new'">
            <v-btn block color="error" @click.stop="dialog = true">
              差し戻し
            </v-btn>
          </v-col>

          <v-col cols="12" md="4" v-if="reservedID === 'new'">
            <v-btn color="accent" block @click="postEvent">
              予約内容を管理者にメールする
            </v-btn>
          </v-col>

          <v-dialog v-model="hdialog" max-width="600">
            <v-card>
              <v-card-text>
                <v-text-field
                  label="PINコードを入力してください(半角数字4桁)"
                  v-model="pin"
                  :rules="pinRules"
                ></v-text-field
                >休日を選択するにはPINが必要です、担当者に連絡ください
              </v-card-text>
              <v-card-actions>
                <v-btn @click="hdialog = false">キャンセル</v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="verifyPIN()">OK</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialog" max-width="600">
            <v-card>
              <v-card-title>差し戻し理由の記載</v-card-title>
              <v-card-text>
                <v-textarea
                  outlined
                  v-model="reason"
                  :rules="reasonRule"
                ></v-textarea>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn @click="dialog = false">キャンセル</v-btn>

                <v-btn color="error" @click="deleteEventWithReason"
                  >差し戻し送信</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="cdialog" max-width="600">
            <v-card>
              <v-card-title>承認内容の記載</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-file-input
                      v-model="excelfile"
                      label="送信するxlsxファイルを選択してください"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      v-if="displayFloorType(this.floor) === '2F'"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea outlined v-model="models.creason"></v-textarea>
                  </v-col>
                  <v-col cols="6">
                    <v-btn
                      @click="ccEmails.push('')"
                      :disabled="ccEmails.length > 10"
                      >CCの宛先を追加する</v-btn
                    >
                  </v-col>
                  <v-col cols="6">
                    <v-btn @click="ccEmails.pop()">CCの宛先を削除する</v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" v-for="(_, i) in ccEmails" :key="i">
                    <v-text-field
                      :label="`${i + 1}人目`"
                      v-model="ccEmails[i]"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn @click="cdialog = false">キャンセル</v-btn>

                <v-btn color="error" @click="validateApproval">確定送信</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-container>
    </v-form>
    <v-footer v-if="reservedID === 'new'">
      <v-row>
        <v-col cols="12" md="1">
          <v-btn text color="#5bb4b4" @click="jump('ROOMAB')">ROOM A/B</v-btn>
        </v-col>
        <v-col cols="12" md="1">
          <v-btn text color="#5bb4b4" @click="jump('WETLAB')">WET LAB</v-btn>
        </v-col>
        <v-col cols="12" md="1">
          <v-btn
            text
            color="#5bb4b4"
            class="text-capitalize"
            @click="jump('CatheterRoom')"
            >Catheter&nbsp;Room</v-btn
          >
        </v-col>
        <v-col cols="12" md="1" class="text-left">
          <v-btn
            text
            color="#5bb4b4"
            href="https://tact-tokyo.co.jp/pg845449.html"
            >アクセスMAP</v-btn
          >
        </v-col>
        <v-col cols="12" md="2" class="text-right">
          <v-btn text color="#5bb4b4" @click="jump('NLS')">NLS</v-btn>
        </v-col>
        <v-col cols="12" md="1" class="text-left">
          <v-btn
            text
            color="#5bb4b4"
            href="https://tact-tokyo.co.jp/pg721876.html"
            >アクセスMAP</v-btn
          >
        </v-col>
        <v-col cols="12" md="2" class="text-right">
          <v-btn text color="#5bb4b4" @click="jump('SLS')">SLS</v-btn>
        </v-col>
        <v-col cols="12" md="1" class="text-left">
          <v-btn
            text
            color="#5bb4b4"
            href="https://tact-tokyo.co.jp/pg721877.html"
            >アクセスMAP</v-btn
          >
        </v-col>
        <v-col cols="12" class="text-center caption">
          <font color="#5bb4b4"
            >{{ new Date().getFullYear() }}.株式会社TACT. All Rights
            Reserved.</font
          >
        </v-col>
      </v-row>
    </v-footer>

    <EventSnackbars :message="snackmessage" color="error" />

    <v-snackbar v-model="invalidToken" top color="error" :timeout="-1">
      <span>トークンが無効ですLoginし直してください</span>
      <v-btn dark text @click="snackbar = false" to="/login">Login</v-btn>
    </v-snackbar>

    <v-snackbar v-model="invalidPasswd" top color="error">
      <span>ユーザー名もしくはパスワードが異なります</span>
      <v-btn dark text @click="snackbar = false">閉じる</v-btn>
    </v-snackbar>

    <v-snackbar v-model="snackbar" top color="error">
      <span v-html="errorMessage"></span>
      <v-btn dark text @click="snackbar = false">閉じる</v-btn>
    </v-snackbar>

    <v-snackbar v-model="successReserved" top color="success">
      <span v-html="successMessage"></span>
      <v-btn dark text @click="reload()">閉じる</v-btn>
    </v-snackbar>

    <v-snackbar v-model="pinSnackbar" top color="warning">
      <span>Pinコードが違います</span>
      <v-btn dark text @click="pinSnackbar = false">閉じる</v-btn>
    </v-snackbar>

    <v-row justify="center">
      <v-dialog
        v-model="authDialog"
        persistent
        max-width="480"
        overlay-opacity="1"
      >
        <v-card>
          <v-card-title>login</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="authContext.user"
                  label="user name"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="authContext.password"
                  label="password"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider />
            <br />
            <v-row justify="center">
              <v-btn @click="login">login</v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'
import AxiosEvents from '../services/AxiosEvents.js'
import dateutil from '../services/dateutil.js'
import moment from 'moment'
import JapaneseHoliday from '../externaldata/JapaneseHoliday.js'
import KJ from '../components/KJ.vue'
import EventRoomName from '../components/EventRoomName.vue'
import EventUsage from '../components/EventUsage.vue'
import EventCalendar from '../components/EventCalendar'
import EventSnackbars from '../components/EventSnackbars'

const api = axios.create({
  baseURL: 'https://tact-auth-l2oi27p23a-an.a.run.app',
  //baseURL: 'http://localhost:8080',
})

const isNetworkError = (err) => !!err.isAxiosError && !err.response

const message = {
  ErrDuplicateTo: 'メールの宛先とCCの宛先が重複しています',
  ErrDuplicateCC: 'CCの宛先が重複しています',
  ErrValidate:
    'フォームチェックエラー<br />エラー内容を確認して正しい値を入れてください',
  ErrDoubleBooking:
    'すでに別の予約が存在します<br />別の日付を指定してください',
  ErrNotExit: 'すでに予約が存在しません',
  ErrNetWork:
    'サーバーが見つかりません。<br />九段ラーニングスタジオにお問い合わせください。',
  ErrServerInternal:
    'サーバーでエラーが発生しております。<br />九段ラーニングスタジオにお問い合わせください。',
  PostedReservation:
    '正常に、予約が完了しました。<br />担当者からのメールをお待ちください',
  ComformedReservation: '予約の確定をして<br />受講者にメールを送信しました',
  CancelReservation: '予約を取り消して、担当者にメールを送信しました',
  SaveReservation: '一時的に編集内容を保存しました',
  LoadReservation: '編集内容を復元しました',
}

const dayOfWeek = ['日', '月', '火', '水', '木', '金', '土']

function readAsDataURL(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = () => {
      reject(reader.error)
    }
    reader.readAsDataURL(blob)
  })
}

export default {
  name: 'Reservation',
  components: {
    KJ,
    EventRoomName,
    EventUsage,
    EventCalendar,
    EventSnackbars,
  },
  props: {
    authDialog: {
      type: Boolean,
      default: false,
    },
    reservedID: {
      type: String,
    },
    floor: {
      type: String,
    },
  },
  computed: {
    displayOther() {
      if (this.displayFloorType(this.floor) === 'SLS') {
        return '内容詳細'
      } else {
        return this.labels.other
      }
    },
    viewUsage() {
      switch (this.floor) {
        case 'SLS':
          if (this.models.division === 'WebRoom') {
            return true
          }
          return false
        case '2F':
          return false
      }
      return true
    },
    desired() {
      var desire = ''
      if (this.models.desiredDate !== '') {
        const rawdate = this.models.desiredDate.split('-')
        const date = new Date(rawdate[0], rawdate[1] - 1, rawdate[2])
        desire +=
          `${this.models.desiredDate}(${dayOfWeek[date.getDay()]})` + ' '
      }
      if (this.models.startTime !== '') {
        desire += this.models.startTime + ' '
      }
      if (this.models.startTime !== '' && this.models.endTime !== '') {
        desire += '- '
      }
      if (this.models.endTime !== '') {
        desire += this.models.endTime
      }
      return desire
    },
    totalB1() {
      var total = 0
      Object.values(this.customModels.B1.number).forEach(
        (val) => (total += Number(val))
      )
      return total
    },
    numTotal() {
      var total = 0
      Object.values(this.customModels.numbers).forEach(
        (val) => (total += Number(val))
      )
      return total
    },
    numMessages() {
      return [
        Object.values(this.customLabels.numbers).join('と') + 'の合計人数',
      ]
    },
    isOtherLayout() {
      return (
        this.customModels.tableLayout === 'その他' ||
        this.customModels.tableLayout === 'Other'
      )
    },
    isOtherMedicalDepartment() {
      return this.customModels.medicalDepartment === 'その他'
    },
    subFloorType() {
      if (this.floor === 'NLS' || this.floor === 'SLS') {
        switch (this.models.division) {
          case 'BW':
            return 'B1'
          case 'ETHICON':
            return '5F'
          case 'AMO':
            return '2F'
          case 'WebRoom':
            return 'SLS'
        }
      }
      return ''
    },
  },
  updated() {
    this.$refs.tactRoom5F.validate()
    if (this.displayFloorType(this.floor) === '2F') {
      this.labels.department = 'エリア(所属部署)'
    } else {
      this.labels.department = '所属部署'
    }
    if (
      this.customModels.roomTypes !== undefined &&
      this.customModels.roomTypes[0] === 'motidasi' &&
      this.models.duration !== 12
    ) {
      this.models.duration = 12
      this.durationItems = [12]
    }
  },
  mounted() {
    this.crumbs.push({
      text: this.floor,
      disabled: false,
      to: `/events/${this.floor}`,
    })
    document.title = this.floor

    this.models.startBuffer = this.bufferItems[4]
    this.models.endBuffer = this.bufferItems[4]

    if (this.floor === 'NLS') {
      this.customModels.roomTypes = ['nls']
    }
    if (this.floor === 'SLS') {
      this.customModels.roomTypes = ['sls']
      this.NLSList = ['AMO', 'ETHICON', 'BW', 'WebRoom']
    }
    if (this.floor === '2F') {
      this.usage = 'LAB'
      this.labels.department = 'エリア(所属部署)'
      this.bufferItems = [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4.0, 4.5]
      this.models.startBuffer = this.bufferItems[1]
      this.models.endBuffer = this.bufferItems[1]
      this.models.superior = '2FloorSuperior'
    }

    this.$refs.tactRoom5F.validate()
    if (this.reservedID === 'new') {
      return
    }
    this.crumbs.push({ text: this.reservedID, disabled: true })
    this.$refs.tactRoom5F.validate()
    if (this.floor === '5F') {
      this.models.duration = this.durationItems[5]
    } else {
      this.models.duration = this.durationItems[7]
    }

    if (localStorage.token) {
      var token = localStorage.token
      AxiosEvents.getEventData(token, this.floor, this.reservedID)
        .then(({ data: { event, detail } }) => {
          console.log(event)
          console.log(detail)
          this.currentState = event.state

          this.models.name = detail.user.name
          this.models.event = detail.summary
          this.models.department = detail.user.department
          this.models.division = detail.user.division
          this.models.email = detail.user.email
          this.models.phone = detail.user.phone
          this.models.costCode = detail.costCode
          this.models.superior = detail.user.superior
          this.customModels.roomTypes = detail.floor.rooms
          this.customModels.tableLayout = detail.floor.layout
          this.customModels.otherLayoutDetail = detail.floor.other
          this.usage = detail.usage
          this.startTimeItems = [detail.event.start]
          this.models.startTime = detail.event.start
          const endTime = dateutil.getEndTime(
            detail.event.date,
            detail.event.start,
            detail.event.duration
          )
          this.endTimeItems = [endTime]
          this.models.endTime = endTime
          this.models.desiredDate = detail.event.date
          this.models.duration = detail.event.duration
          this.models.startBuffer = detail.event.buffer.start
          this.models.endBuffer = detail.event.buffer.end
          if (this.floor === 'SLS') {
            this.customModels.divisionDetail = detail.meta.divisionDetail
          }
          if (this.displayFloorType(this.floor) === 'B1') {
            this.customModels.B1.number = detail.participants
            this.customModels.kjSettings = detail.meta.bw
          } else {
            this.customModels.medicalDepartment = detail.meta.medicalDepartment
            this.customModels.otherMedicalDepartment =
              detail.meta.otherMedicalDepartment
            this.customModels.numbers.inHouse = detail.participants.internal
            if (detail.participants.external) {
              this.customModels.numbers.outHouse = detail.participants.external
            } else {
              this.customModels.numbers.external = 0
            }
            if (detail.participants.other) {
              this.customModels.numbers.other = detail.participants.other
            } else {
              this.customModels.numbers.external = 0
            }
          }
          this.models.other = detail.other
          this.$store.dispatch('save', {
            models: this.models,
            custom: this.customModels,
            usage: this.usage,
          })
        })
        .catch((err) => {
          console.log(err.response.status)
          if (err.response && err.response.status == 401) {
            console.log('invalid token')
            this.invalidToken = true
          }
        })
    }
  },
  methods: {
    jump(floor) {
      var basepath = 'public'
      switch (floor) {
        case 'ROOMAB': {
          const link = () => {
            location.href =
              'https://tact-client-new-3dakogmbua-an.a.run.app/public/ROOMAB/new'
          }
          setTimeout(link, 0)
          break
        }
        case 'WETLAB': {
          const link = () => {
            location.href =
              'https://tact-client-new-3dakogmbua-an.a.run.app/with-login-public/WETLAB/new'
          }
          setTimeout(link, 0)
          break
        }
        case 'CatheterRoom': {
          const link = () => {
            location.href =
              'https://tact-client-new-3dakogmbua-an.a.run.app/public/CatheterRoom/new'
          }
          setTimeout(link, 0)
          break
        }
        case 'NLS':
          basepath = 'with-login-public'
          break
        case 'SLS': {
          const link = () => {
            location.href =
              'https://tact-client-new-3dakogmbua-an.a.run.app/public/SLS/new'
          }
          setTimeout(link, 0)
          break
        }
      }
      this.$router.push(`/${basepath}/${floor}/new`)
    },
    isShowHoliday() {
      return this.reservedID !== 'new' || localStorage.token
    },
    async login() {
      try {
        await api.post(`/user-access-ctl/${this.floor}/users/hash`, {
          userName: this.authContext.user,
          passwd: this.authContext.password,
        })
        this.$router.push(`/public/${this.floor}/new`)
      } catch (err) {
        this.invalidPasswd = true
      }
    },
    displayFloorType(floor) {
      if (floor === 'NLS' || floor == 'SLS') {
        return this.subFloorType
      }
      return floor
    },
    errorhandling(err) {
      if (err.code) {
        console.log(err.code)
      }
      if (err.response) {
        const status = err.response.status
        const data = err.response.data
        if (status === 400 && data) {
          if (data.code === 1) {
            this.snackmessage = message.ErrDoubleBooking
          }
        }
        if (status === 500) {
          this.snackmessage = message.ErrServerInternal
        }
      }
      if (isNetworkError(err)) {
        this.snackmessage = message.ErrNetWork
      }
      console.log(err)
    },
    verifyPIN: async function () {
      try {
        const token = this.$store.state.token
        const { data } = await AxiosEvents.postPin(token, this.floor, this.pin)
        this.holiday = data.velify
        if (this.holiday === false) {
          this.pinSnackbar = true
        } else {
          this.disabled = true
        }
      } catch (err) {
        this.errorhandling(err)
      } finally {
        this.hdialog = false
      }
    },
    disallowed: function (val) {
      const d = moment(val)
      if (this.holiday) {
        return true
      }
      const s = d.format('YYYY-MM-DD')
      if (JapaneseHoliday.indexOf(s) !== -1) {
        return false
      }
      return !(d.day() === 0 || d.day() === 6)
    },
    reload() {
      this.successReserved = false
      location.reload()
    },
    async deleteEventWithReason() {
      try {
        this.$refs.tactRoom5F.validate()
        if (this.reason) {
          console.log(this.reason)
          await AxiosEvents.deleteEventData(
            this.$store.state.token,
            this.floor,
            this.reservedID,
            this.reason
          )
          this.successMessage = message.CancelReservation
          this.successReserved = true
          this.dialog = false
        }
      } catch (err) {
        this.errorMessage = message.ErrNotExit
        this.snackbar = true
      }
    },
    async deleteEvent() {
      try {
        await AxiosEvents.deleteEventData(
          this.$store.state.token,
          this.floor,
          this.reservedID,
          null
        )
        this.successMessage = message.CancelReservation
        this.successReserved = true
      } catch (err) {
        this.errorMessage = message.ErrNotExit
        this.snackbar = true
      }
    },
    async validateApproval() {
      this.$refs.tactRoom5F.validate()
      console.log(this.reservedID)
      const ccsSet = new Set(this.ccEmails)
      if (this.ccEmails.indexOf(this.models.email) !== -1) {
        this.errorMessage = message.ErrDuplicateTo
        this.snackbar = true
        return
      }
      if (this.ccEmails.length !== ccsSet.size) {
        this.errorMessage = message.ErrDuplicateCC
        this.snackbar = true
        return
      }

      if (
        this.displayFloorType(this.floor) === '2F' &&
        this.excelfile !== undefined
      ) {
        this.customModels.filebase64 = await readAsDataURL(this.excelfile)
        this.customModels.filename = this.excelfile.name
      }

      if (this.formValidity) {
        try {
          await AxiosEvents.putEventData(
            this.$store.state.token,
            this.floor,
            this.reservedID,
            this.usage,
            this.models,
            this.customModels,
            this.ccEmails
          )
          this.successMessage = message.ComformedReservation
          this.successReserved = true
        } catch (err) {
          console.log(err)
          this.errorhandling(err)
        }
      } else {
        this.errorMessage = message.ErrValidate
        this.snackbar = true
      }
    },
    validateSave() {
      this.$refs.tactRoom5F.validate()
      if (this.formValidity) {
        this.$store.dispatch('save', {
          reservedID: this.reservedID,
          models: this.models,
          custom: this.customModels,
          usage: this.usage,
        })
        this.successMessage = message.SaveReservation
        this.successReserved = true
      } else {
        this.errorMessage = message.ErrValidate
        this.snackbar = true
      }
    },
    validateLoad() {
      const reg = localStorage.getItem(this.reservedID)
      if (reg) {
        const data = JSON.parse(reg)
        this.models = data.models
        this.customModels = data.custom
        this.usage = data.usage
        this.successMessage = message.LoadReservation
        this.successReserved = true
      } else {
        this.errorMessage = message.ErrNotExit
        this.snackbar = true
      }
    },
    async postEvent() {
      console.log(this.floor)
      this.$refs.tactRoom5F.validate()
      if (this.formValidity) {
        try {
          await AxiosEvents.postEventData(
            this.$store.state.token,
            this.floor,
            this.usage,
            this.models,
            this.customModels
          )
          this.successMessage = message.PostedReservation
          this.successReserved = true
          // location.reload()
        } catch (err) {
          this.errorhandling(err)
        }
      } else {
        this.errorMessage = message.ErrValidate
        this.snackbar = true
      }
    },
    focusStartTime() {
      AxiosEvents.getFreeSchedule(
        this.$store.state.token,
        this.floor,
        this.models.desiredDate,
        this.models.duration,
        {
          rooms: this.customModels.roomTypes,
        },
        this.reservedID
      )
        .then(({ data }) => {
          this.startTimeItems = []
          this.endTimeItems = []
          data.forEach((val) => {
            this.startTimeItems.push(val.start)
            this.endTimeItems.push(val.end)
            this.models.duration = val.duration
          })
        })
        .catch((err) => {
          this.errorhandling(err)
        })
    },
    selectStartTime() {
      const endTime = dateutil.getEndTime(
        this.models.desiredDate,
        this.models.startTime,
        this.models.duration
      )
      this.endTimeItems = [endTime]
      this.models.endTime = endTime
    },
    clearStartTime() {
      this.models.startTime = ''
      this.models.endTime = ''
    },
  },
  watch: {
    models: {
      handler: function (val, old) {
        console.log(val, old)
        if (this.floor === 'SLS' && val.division !== 'WebRoom') {
          this.customModels.divisionDetail = val.division
        }
      },
      deep: true,
    },
    usage(newUsage) {
      switch (newUsage) {
        case 'LAB':
          if (this.reservedID === 'new') {
            this.models.duration = 4.0
          }
          this.durationItems = [
            0.5,
            1,
            1.5,
            2,
            2.5,
            3,
            3.5,
            4,
            4.5,
            5,
            5.5,
            6,
            6.5,
            7,
            7.5,
            8,
            8.5,
            9,
            9.5,
            10,
            10.5,
            11,
            11.5,
            12,
          ]
          break
        default:
          if (this.reservedID === 'new') {
            this.models.duration = 3.0
          }
          this.durationItems = [
            0.5,
            1,
            1.5,
            2,
            2.5,
            3,
            3.5,
            4,
            4.5,
            5,
            5.5,
            6,
            6.5,
            7,
            7.5,
            8,
            8.5,
            9,
            9.5,
            10,
            10.5,
            11,
            11.5,
            12,
          ]
          break
      }
      // this.clearStartTime()
    },
  },
  data: () => ({
    NLSList: ['BW', 'ETHICON', 'AMO'],
    invalidToken: false,
    invalidPasswd: false,
    authedUserList: [],
    excelfile: undefined,
    authContext: {
      user: '',
      password: '',
    },
    ccEmails: [],
    snackmessage: '',
    currentState: 0,
    cdialog: false,
    disabled: false,
    pinSnackbar: false,
    pin: '',
    crumbs: [{ text: 'top', disabled: false, to: '/top' }],
    holiday: false,
    hdialog: false,
    dialog: false,
    reason: '',
    successMessage: '',
    errorMessage: '',
    snackbar: false,
    successReserved: false,
    openTime: 9,
    colseTime: 21,
    usage: '',
    formValidity: false,
    otherLayoutLabel: 'その他レイアウト',
    startTimeItems: [],
    endTimeItems: [],
    tableLayoutItems: ['スクール形式', 'コの字', 'シアター形式', 'Other'],
    bufferItems: [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4.0, 4.5],
    durationItems: [
      0.5,
      1,
      1.5,
      2,
      2.5,
      3,
      3.5,
      4,
      4.5,
      5,
      5.5,
      6,
      6.5,
      7,
      7.5,
      8,
      8.5,
      9,
      9.5,
      10,
      10.5,
      11,
      11.5,
      12,
    ],
    medicalDepartmentItems: [
      '外科',
      '呼吸器外科',
      '産婦人科',
      '泌尿器科',
      '心臓外科',
      '循環器内科',
      '眼科',
      '脳神経外科',
      '整形外科',
      'その他',
    ],
    labels: {
      event: 'イベント名 (顧客名/施設等)',
      name: '担当者(フルネーム)',
      division: '事業部',
      department: '所属部署',
      email: 'メールアドレス',
      phone: '連絡先電話番号',
      costCode: 'コストコード',
      superior: '上長名(フルネーム)',
      desiredDate: '利用希望日',
      duration: '利用時間 (時間)',
      startTime: '開始時間',
      startBuffer: '準備時間',
      endTime: '終了時間',
      endBuffer: '後片付け時間',
      other: '術式/テーブル数/リクエスト等',
    },
    models: {
      event: '',
      name: '',
      division: '',
      department: '',
      email: '',
      phone: '',
      costCode: '',
      superior: '',
      desiredDate: '',
      duration: 0,
      startTime: '',
      startBuffer: 0,
      endTime: '',
      endBuffer: 0,
      other: '',
      creason: '',
    },
    customLabels: {
      medicalDepartment: '診療科',
      numbers: {
        inHouse: '社内',
        outHouse: '社外',
        other: '外部利用者',
      },
      roomTypes: 'ルームタイプ',
      tableLayout: 'テーブルレイアウト',
      usage: '利用内容',
    },
    customModels: {
      divisionDetail: '',
      filename: '',
      filebase64: '',
      numbers: {
        inHouse: '0',
        outHouse: '0',
        other: '0',
      },
      medicalDepartment: '',
      otherMedicalDepartment: '',
      roomTypes: [],
      tableLayout: '',
      otherLayoutDetail: '',
      kjSettings: {
        kj: true,
        merge: false,
        heartModel: '',
        catAndCurve: '',
      },
      B1: {
        number: {
          coMed: 0,
          agency: 0,
          doctor: 0,
          inHouse: 0,
        },
      },
    },
    divisionDetailRules: [(val) => !!val || '事業部を記述してください'],
    reasonRule: [(val) => !!val || '理由を記載してください'],
    eventRules: [(val) => !!val || 'イベント名を入力しください'],
    nameRules: [(val) => !!val || '名前(フルネーム)を入力してください'],
    divisionRules: [(val) => !!val || '事業部名を入力してください'],
    departmentRules: [(val) => !!val || '所属部署名を入力してください'],
    emailRules: [
      (val) =>
        val.indexOf('@') !== 0 || '@の前にユーザーネームを入力してください',
      (val) => val.includes('@') || '@が含まれていません',
      (val) => val.includes('.') || '.が含まれていません',
      (val) => /^.+@.+\...+/.test(val) || 'ドメインが含まれていません',
    ],
    phoneRules: [
      (val) =>
        /^[0-9-]+$/.test(val) ||
        '数字もしくはハイフン以外の文字を入れないでください(半角入力)',
      (val) =>
        val.indexOf('-') !== 0 || '数字から始まるようにしてください(半角入力)',
    ],
    costCodeRules: [
      (val) =>
        /^[0-9]{10}$/.test(val) || '10桁の数字を入力してください(半角入力)',
    ],
    pinRules: [
      (val) =>
        /^[0-9]{4}$/.test(val) || '4桁の数字を入力してください(半角入力)',
    ],
    superiorRules: [(val) => !!val || '上長名(フルネーム)を入力してください'],
    numberInHouseRules: [
      (val) => /^[0-9]+$/.test(val) || '数字を入力してください(半角入力)',
      (val) => val > 0 || '最低でも1以上の参加人数を入力してください(半角入力)',
    ],
    numberOutHouseRules: [
      (val) => /^[0-9]+$/.test(val) || '数字を入力してください(半角入力)',
    ],
    roomtypeRules: [
      (array) => array.length > 0 || 'ルームタイプを選択してください',
    ],
    tableLayoutRules: [
      (val) => !!val || 'テーブルレイアウトを選択してください',
    ],
    usageRules: [(val) => !!val || '利用内容を選択してください'],
    desiredDateRules: [
      (val) =>
        /^[0-9]{4}-[0-9]{2}-[0-9]{2}\(.+\)/.test(val) ||
        'カレンダーから利用日を選択してください',
      (val) =>
        /^[0-9]{4}-[0-9]{2}-[0-9]{2}\(.+\) [0-9]{2}:[0-9]{2}/.test(val) ||
        '開始時間を選択してください',
      (val) =>
        /^[0-9]{4}-[0-9]{2}-[0-9]{2}\(.+\) [0-9]{2}:[0-9]{2} - [0-9]{2}:[0-9]{2}/.test(
          val
        ) || '終了時間を選択してください',
    ],
    startRule: [
      (val) =>
        !!val ||
        '開始時間を選択する前に利用時間とルームタイプを選択してください',
      (val) => !!val || '開始時間を選択する前に希望日を選択してください',
    ],
  }),
}
</script>

<style>
.overflow {
  overflow-x: auto;
}
.mgr {
  margin-right: 30px;
}
.div-group {
  padding: 0;
  margin: 0;
}
</style>
