<template>
  <div>
    <v-snackbar v-model="snackbar" top :color="color">
      <span v-html="message"></span>
      <v-btn @click="snackbar = false" dark text>閉じる</v-btn>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  props: {
    message: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'success'
    }
  },
  watch: {
    message: function(newVal) {
      if (newVal) {
        this.snackbar = true
      }
    }
  },
  data: function(){
    return {
      snackbar: false
    }
  },
  methods: {
    notify: function() {
      this.snackbar = false
      this.$emit('close')
    }
  }
})
</script>
