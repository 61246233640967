<template>
  <div>
    <v-row justify="center" v-if="floor == 'SLS'">
      <div v-if="reservedID !== 'new'" class="overflow">
        <v-col>
          <!-- SLS Private -->
          <iframe
            src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=Asia%2FTokyo&amp;src=Y192bThkdmdxYTJmdm1ub29tc3JpaHZwZXJvY0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&amp;src=Y18xM3RsazliajkwdWIzM2llY2QzdmxybnI5c0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&amp;src=Y19rN3RkaG9lNzc4OG5iMGpnc3VzdDU1c2U1Z0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&amp;src=Y19jZm91cXI0M290b25vc3JyM2sycGNsMzk2a0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&amp;color=%23A79B8E&amp;color=%23D50000&amp;color=%230B8043&amp;color=%2333B679&amp;title=SLS"
            style="border: solid 1px #777"
            :width="responsiveCalendarWidth"
            :height="responsiveCalendarHeight"
            frameborder="0"
            scrolling="no"
          ></iframe>
        </v-col>
      </div>
      <div v-else class="overflow">
        <v-col>
          <!-- SLS Public -->
          <iframe
            src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=Asia%2FTokyo&amp;src=Y192bThkdmdxYTJmdm1ub29tc3JpaHZwZXJvY0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&amp;src=Y18xM3RsazliajkwdWIzM2llY2QzdmxybnI5c0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&amp;src=Y19jZm91cXI0M290b25vc3JyM2sycGNsMzk2a0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&amp;color=%23A79B8E&amp;color=%23D50000&amp;color=%2333B679&amp;title=SLS"
            style="border: solid 1px #777"
            :width="responsiveCalendarWidth"
            :height="responsiveCalendarHeight"
            frameborder="0"
            scrolling="no"
          ></iframe>
        </v-col>
      </div>
    </v-row>
    <v-row justify="center" v-if="floor == 'NLS'">
      <div v-if="reservedID !== 'new'" class="overflow">
        <v-col>
          <!-- NLS Private -->
          <iframe
            src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=Asia%2FTokyo&amp;src=ZzZkdW41OW4xcThkaDlvNmg1cGZ0ZTZ0dnNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=YXRtaTE5NmNjdmM5MXZ2OG1lY2Rrcm5oMjBAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=NGFnNjk3ZzgyN3FvN2RiOWt1M3R2OXRoOGdAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;color=%2333B679&amp;color=%23A79B8E&amp;color=%23D50000&amp;title=NLS"
            style="border: solid 1px #777"
            :width="responsiveCalendarWidth"
            :height="responsiveCalendarHeight"
            frameborder="0"
            scrolling="no"
          ></iframe>
        </v-col>
      </div>
      <div v-else class="overflow">
        <v-col>
          <!-- NLS Public -->
          <iframe
            src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=Asia%2FTokyo&amp;src=YXRtaTE5NmNjdmM5MXZ2OG1lY2Rrcm5oMjBAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=aHU5anZkMXV0cG5rYXUyNjgxZGw5cW43anNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=NGFnNjk3ZzgyN3FvN2RiOWt1M3R2OXRoOGdAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;color=%23A79B8E&amp;color=%23039BE5&amp;color=%23D50000&amp;title=NLS"
            style="border: solid 1px #777"
            :width="responsiveCalendarWidth"
            :height="responsiveCalendarHeight"
            frameborder="0"
            scrolling="no"
          ></iframe>
        </v-col>
      </div>
    </v-row>
    <v-row justify="center" v-if="floor == '5F'">
      <div v-if="reservedID !== 'new'" class="overflow">
        <v-col>
          <!-- 5F Private -->
          <iframe
            src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=Asia%2FTokyo&amp;src=dGFjdC1rbHMuY29tX2VxN2RhZGZqdDdxbzF2MnZyaDJlc3I4YjEwQGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20&amp;src=Y184a282bnZycTVnbGhsOGVzanBmbG1jcmFnOEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&amp;src=dGFjdC1rbHMuY29tX2Rlbmlvc200Z2hrcG9yOWhra2hyYmxmcjg4QGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20&amp;color=%23A79B8E&amp;color=%23D50000&amp;color=%230B8043&amp;title=5F-Private"
            style="border: solid 1px #777"
            :width="responsiveCalendarWidth"
            :height="responsiveCalendarHeight"
            frameborder="0"
            scrolling="no"
          ></iframe>
        </v-col>
      </div>
      <div v-else class="overflow">
        <v-col>
          <!-- 5F Public -->
          <iframe
            src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=Asia%2FTokyo&amp;src=dGFjdC1rbHMuY29tX2VxN2RhZGZqdDdxbzF2MnZyaDJlc3I4YjEwQGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20&amp;src=Y184a282bnZycTVnbGhsOGVzanBmbG1jcmFnOEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&amp;src=dGFjdC1rbHMuY29tXzExdWd0YjFuZjhhazBibTlqMW5sbmNwZXFnQGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20&amp;color=%23A79B8E&amp;color=%23D50000&amp;color=%2333B679&amp;title=KLS-5F"
            style="border: solid 1px #777"
            :width="responsiveCalendarWidth"
            :height="responsiveCalendarHeight"
            frameborder="0"
            scrolling="no"
          ></iframe>
        </v-col>
      </div>
    </v-row>
    <v-row justify="center" v-if="floor == 'B1'">
      <div v-if="reservedID !== 'new'" class="overflow">
        <v-col>
          <!-- B1 Private -->
          <iframe
            src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=Asia%2FTokyo&amp;src=Y19kc2doYjBoZDM0bjU5OTY2NjM1bzY2amp0Y0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&amp;src=Y184ZDIwa2c4OGczNW8wZ3NmODRsZm43OGVqNEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&amp;src=Y19majQ3cDV1NXB1ajdqc3Zpb3M0NmFrMHVqc0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&amp;color=%23A79B8E&amp;color=%23D50000&amp;color=%238E24AA&amp;title=B1-Private"
            style="border: solid 1px #777"
            :width="responsiveCalendarWidth"
            :height="responsiveCalendarHeight"
            frameborder="0"
            scrolling="no"
          ></iframe>
        </v-col>
      </div>
      <div v-else class="overflow">
        <v-col>
          <!-- B1 Public -->
          <iframe
            src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=Asia%2FTokyo&amp;src=Y19kc2doYjBoZDM0bjU5OTY2NjM1bzY2amp0Y0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&amp;src=Y184ZDIwa2c4OGczNW8wZ3NmODRsZm43OGVqNEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&amp;src=Y182N2o0M21oanE5c2RuNTlybDVsZ21oYTZob0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&amp;color=%23A79B8E&amp;color=%23D50000&amp;color=%23B39DDB&amp;title=KLS-B1"
            style="border: solid 1px #777"
            :width="responsiveCalendarWidth"
            :height="responsiveCalendarHeight"
            frameborder="0"
            scrolling="no"
          ></iframe>
        </v-col>
      </div>
    </v-row>
    <v-row justify="center" v-if="floor == '2F'">
      <!-- 2F Private -->
      <div v-if="reservedID !== 'new'" class="overflow">
        <v-col>
          <iframe
            src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=Asia%2FTokyo&amp;src=Y19qbG11YzdoN2oyaG5tajAxY2llYm45dGZsc0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&amp;src=Y183aDJmOWRjdjk1ZHMwbHI0bThwMWkwbGc5Y0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&amp;color=%23D50000&amp;color=%234285F4&amp;title=2F"
            style="border: solid 1px #777"
            :width="responsiveCalendarWidth"
            :height="responsiveCalendarHeight"
            frameborder="0"
            scrolling="no"
          ></iframe>
        </v-col>
      </div>
      <!-- 2F Public -->
      <div v-else class="overflow">
        <v-col>
          <iframe
            src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=Asia%2FTokyo&amp;src=Y19qbG11YzdoN2oyaG5tajAxY2llYm45dGZsc0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&amp;src=Y19rbmQ3bzNpdjJrdDNibW1wdmk5djY5aWJza0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&amp;color=%23D50000&amp;color=%233F51B5&amp;title=2F"
            style="border: solid 1px #777"
            :width="responsiveCalendarWidth"
            :height="responsiveCalendarHeight"
            frameborder="0"
            scrolling="no"
          ></iframe>
        </v-col>
      </div>
    </v-row>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  props: {
    floor: {
      type: String,
    },
    reservedID: {
      type: String,
    },
  },
  computed: {
    responsiveCalendarWidth: function () {
      if (this.$vuetify.breakpoint.xs) {
        return 800
      }
      return 1600
    },
    responsiveCalendarHeight: function () {
      if (this.$vuetify.breakpoint.xs) {
        return 400
      }
      return 700
    },
  },
})
</script>
