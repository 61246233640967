<template>
  <v-select
    label="利用内容"
    :items="items"
    :value="usage"
    @change="$emit('update:usage', $event)"
    :rules="rule"
    chips
  >
  </v-select>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  props: {
    floor: {
      type: String,
    },
    usage: {
      type: String,
    },
  },
  data: function () {
    return {
      usageItems5F: ['LAB', '会議利用', 'セミナー', 'Webセミナー', 'Web会議'],
      usageItemsB1: ['LAB', '会議利用', 'セミナー', '水槽実験'],
      usageItems2F: ['LAB', '会議利用'],
      usageItemsSLS: ['Webinar配信', '会議利用'],
      rule: [
        function (val) {
          if (val.length === 0) {
            return '利用内容を選択してください'
          } else {
            return true
          }
        },
      ],
    }
  },
  computed: {
    items: function () {
      switch (this.floor) {
        case '5F':
          return this.usageItems5F
        case '2F':
          return this.usageItems2F
        case 'SLS':
          return this.usageItemsSLS
        default:
          return this.usageItemsB1
      }
    },
  },
})
</script>
