<template>
  <v-form @submit.prevent>
    <v-row>
      <v-col cols="12">
        <v-radio-group
          :value="kj"
          label="KJ Setting"
          row
          @change="$emit('update:kj', $event)"
        >
          <v-radio label="要" :value="true"></v-radio>
          <v-radio label="不要" :value="false"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12">
        <v-row v-if="kj">
          <v-col cols="12">
            <p>
              <b>開始時のセッティング</b>
            </p>
            <p>
              Labの途中でハートモデルを交換する場合は、下方の備考欄に交換後のハートモデルとカテーテルをご記入ください。
            </p>
            <p v-if="floor === 'NLS'">
              使用するカテはご自身でご手配をお願い致します。
            </p>
            <p v-if="floor === 'NLS'">
              水槽実験を実施する際、もしくはKJでST
              SFを使用希望の場合は下記の備考欄にご記入し、手配をお願い致します。
            </p>
          </v-col>
          <v-col cols="4">
            <v-select
              :items="heartModels"
              label="CT(ハートモデル)"
              :value="heartModel"
              item-text="text"
              item-value="abbr"
              @change="isVerify($event)"
            >
            </v-select>
          </v-col>
          <v-col cols="4">
            <v-select
              :items="catAndCurves"
              label="カテ&カーブ"
              :value="catAndCurve"
              item-text="text"
              item-value="abbr"
              @change="$emit('update:catAndCurve', $event)"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-row>
              <v-col cols="12">
                <v-radio-group
                  :value="merge"
                  label="Merge"
                  row
                  :disabled="isActiveMerge"
                  @change="$emit('update:merge', $event)"
                >
                  <v-radio label="要" :value="true"></v-radio>
                  <v-radio label="不要" :value="false"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" class="nopadding">
                <p class="red--text nomargin">
                  SOUND Mergeは行っておりません。
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  mounted: function () {
    if (this.floor === 'NLS') {
      this.catAndCurves = [
        { abbr: 'stdf', text: 'ST DF' },
        { abbr: 'stdd', text: 'ST DD' },
        { abbr: 'p500', text: 'SOUND STAR【P500】' },
      ]
    }
  },
  props: {
    kj: {
      type: Boolean,
      default: false,
    },
    merge: {
      type: Boolean,
      default: false,
    },
    heartModel: {
      type: String,
    },
    catAndCurve: {
      type: String,
    },
    floor: {
      type: String,
    },
  },
  data: function () {
    return {
      kjSettings: {
        kj: false,
        merge: false,
        heartModel: '',
        catAndCurve: '',
      },
      heartModels: [
        { abbr: 'model3', text: 'Model3 hole Heart 【PAF】' },
        { abbr: 'acl', text: 'ACL対応ハートモデル【穴あきPAF】' },
        { abbr: 'af101', text: 'AF101【PeAF】' },
        { abbr: 'goffuax', text: 'Goffuax【ULSハートモデル】' },
      ],
      catAndCurves: [
        { abbr: 'stdf', text: 'ST DF' },
        { abbr: 'stdd', text: 'ST DD' },
        // { abbr: 'vividi', text: 'SOUND STAR【vivid i】' },
        { abbr: 'p500', text: 'SOUND STAR【P500】' },
      ],
      isActiveMerge: false,
    }
  },
  methods: {
    isVerify: function (event) {
      if (event === 'goffuax') {
        this.isActiveMerge = true
        this.$emit('update:merge', false)
      } else {
        this.isActiveMerge = false
      }
      this.$emit('update:heartModel', event)
    },
  },
})
</script>

<style>
.nomargin {
  margin: 0 !important;
}
.nopadding {
  padding: 0 !important;
}
</style>
